import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

let theme = createMuiTheme({
  themeName: 'Custom Theme',
  palette: {
    // type: 'dark',
    common: { black: '#000', white: '#fff' },
    // background: { paper: '#fff', default: '#fafafa' },
    primary: {
      light: 'rgba(176, 121, 75, 1)',
      main: 'rgba(111, 62, 19, 1)',
      dark: 'rgba(94, 44, 1, 1)',
      contrastText: '#fff'
    },
    secondary: {
      light: 'rgba(247, 189, 94, 1)',
      main: 'rgba(245, 166, 35, 1)',
      dark: 'rgba(215, 134, 0, 1)',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
  }
});

theme = responsiveFontSizes(theme);

export default theme;
